import et from '@/lib/dictionary/languages/et';
import ru from '@/lib/dictionary/languages/ru';
import en from '@/lib/dictionary/languages/en';
import { Locale } from '@/lib/enums/locale.enum';

export type SiteDictionary = typeof et;

const localeMap: Record<Locale, SiteDictionary> = {
  [Locale.et]: et,
  [Locale.ru]: ru,
  [Locale.en]: en,
};

export const getDictionary = (locale: Locale) => {
  return localeMap[locale] || {};
};
