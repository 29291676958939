import { initReactI18next } from 'react-i18next/initReactI18next';
import { createInstance } from 'i18next';
import { Locale } from '@/lib/enums/locale.enum';
import { SiteDictionary } from '@/lib/dictionary/index';

type I18nInstance = ReturnType<typeof createInstance>;

type Props = {
  locale: Locale;
  dictionary: SiteDictionary;
  instance?: I18nInstance;
};

export enum DictionaryNamespace {
  common = 'common',
}

export type DictionaryInit = {
  i18n: I18nInstance;
  t: I18nInstance['t'];
};

export const initDictionary = async (props: Props): Promise<DictionaryInit> => {
  const instance = props.instance || createInstance();

  instance.use(initReactI18next);

  await instance.init({
    lng: props.locale,
    resources: {
      [props.locale]: {
        [DictionaryNamespace.common]: props.dictionary,
      },
    },
    ns: [DictionaryNamespace.common],
    defaultNS: DictionaryNamespace.common,
    supportedLngs: Object.values(Locale),
  });

  return {
    i18n: instance,
    t: instance.t,
  };
};
